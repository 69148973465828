import React from "react";

// @ts-ignore
import ExcellenceImg from "../assets/img/excellence.png";
// @ts-ignore
import mapImg from "../assets/img/worldMap.png";
import ReactPlayer from "react-player";

const GlobalExcellence = () => {
  return (
    <div className="section bg-warm-light">
      <div
        style={{ backgroundImage: `url(${mapImg})` }}
        className="flex flex-col md:flex-row gap-10 bg-contain"
      >
        <div className="flex-1">
          <div className="">
            <p className="red-heading">Excellence mondiale</p>
            <h2 className="mt-3 mb-10">
              Votre équipe est internationale ?<br />
               La nôtre aussi.
            </h2>
          </div>
          <p>
            Excellence mondiale - c'est ce que nous exigeons de nos coachs. Nous
            évaluons leur excellence grâce à un processus d'entretien en deux
            étapes, des vérifications de références et des évaluations. Nous
            surveillons également leurs performances, ce qui nous permet de
            garantir la qualité du coaching pour toutes les parties prenantes.
          </p>
        </div>
        <div className="lg:flex-1">
          <ReactPlayer
            playing
            muted={true}
            loop={true}
            url="https://www.youtube.com/watch?v=hQxr6Ac_H6E"
            width={"100%"}
            // height={"300px"}
            // style={{ minHeight: "300px" }}
          />
        </div>
        {/*<img className="w-3/5 object-contain" src={ExcellenceImg} />*/}
      </div>
    </div>
  );
};

export default GlobalExcellence;
